import {List} from "immutable";
import SoftwareLicensesState from "../states/SoftwareLicensesState";
import {handleActions} from "redux-actions";
import RootState from "../states/RootState";

const defaultState: SoftwareLicensesState = {
    licenses: List(),
    count: 0,
    offset: 0,
    limit: 25,
    progress: false,
    signedLicense: null,
    error: null,
    shouldUpdateList: false
};

export const SoftwareLicensesReducer = handleActions<SoftwareLicensesState>({
    GET_SOFTWARE_LICENSES_LIST: (state: SoftwareLicensesState, action: any): SoftwareLicensesState => ({
        ...state,
        offset: action.payload.request.params.offset,
        limit: action.payload.request.params.limit,
        licenses: state.licenses,
        progress: true,
        error: null,
        shouldUpdateList: false,
    }),
    GET_SOFTWARE_LICENSES_LIST_SUCCESS: (state, action: any): SoftwareLicensesState => ({
        ...state,
        licenses: List.of(...action.payload.data),
        progress: false,
        error: null,
    }),
    GET_SOFTWARE_LICENSES_LIST_FAIL: (state, action: any): SoftwareLicensesState => ({
        ...state,
        licenses: defaultState.licenses,
        offset: defaultState.offset,
        limit: defaultState.limit,
        progress: false,
        error: action.payload,
    }),
    GENERATE_SOFTWARE_LICENSE: (state: SoftwareLicensesState, action: any): SoftwareLicensesState => ({
        ...state,
        progress: true,
        error: null,
    }),
    GENERATE_SOFTWARE_LICENSE_SUCCESS: (state: SoftwareLicensesState, action: any): SoftwareLicensesState => ({
        ...state,
        progress: false,
        error: null,
        signedLicense: action.payload.data,
    }),
    GENERATE_SOFTWARE_LICENSE_FAIL: (state: SoftwareLicensesState, action: any): SoftwareLicensesState => ({
        ...state,
        progress: false,
        error: action.payload,
    }),
    GET_SOFTWARE_LICENSES_LIST_STATS: (state: SoftwareLicensesState, action: any): SoftwareLicensesState => ({
        ...state,
        count: 0,
        offset: defaultState.offset,
        limit: defaultState.limit,
        progress: true,
        error: null,
    }),
    GET_SOFTWARE_LICENSES_LIST_STATS_SUCCESS: (state: SoftwareLicensesState, action: any): SoftwareLicensesState => ({
        ...state,
        count: action.payload.data.count,
        progress: false,
        error: null,
    }),
    GET_SOFTWARE_LICENSES_LIST_STATS_FAIL: (state: SoftwareLicensesState, action: any): SoftwareLicensesState => ({
        ...state,
        count: 0,
        progress: false,
        error: action.payload,
    }),
}, defaultState);

export const getState = (state: RootState): SoftwareLicensesState => state.components!.softwareLicenses;
